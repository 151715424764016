/** Ad / Load more **/
.ad {
  position: relative;
  font-size: 1.6rem;
  z-index: 1;
  text-transform: uppercase;
  color: #3ac8c4;
  overflow: hidden;
  text-align: center;
}
.ad:before {
  margin-left: -50%;
  text-align: right;
}
.ad:after,
.ad:before {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\A0';
  background-color: #ccc;
}
.ad-btn {
  text-transform: uppercase;
  color: #3ac8c4;
  font-family: Karla, sans-serif;
  text-decoration: none;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 16px;
  margin: 0 16px;
  cursor: pointer;
  outline: none;
}
@media (max-width: 36em) {
  .ad-btn {
    padding: 16px 0;
  }
}

/** breadcrumbs **/
.nav-breadcrumbs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-breadcrumbs {
  margin-top: 1px;
  padding: 0 5%;
}
.bread-link {
  display: block;
  padding: 1.2rem;
  font-weight: 500;
  font-size: 1.6rem;
  text-transform: uppercase;
  color: rgb(55, 55, 55);
}
.bread-link.active {
  color: #f04802;
}
span.arrow {
  padding: 1rem;
  font-size: 1.8rem;
}
#breadcrumbs hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin: 0;
  padding: 0;
  opacity: 0.1;
}

/** Nav **/
.navbar {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row nowrap;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-height: 170px;
  height: 140px;
  top: 0;
  z-index: 100;
  padding: 0 6.5%;
}
.navbar-brand img {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  width: 250px;
  height: 34px;
}
.navbar-nav .nav-item {
  line-height: 50px;
  font-size: 1.4rem;
  display: inline-block;
}
.login {
  margin-right: 24px;
  font-weight: 500;
  text-transform: uppercase;
}
.login .nav-link {
  color: #fd5f00 !important;
}
.circle {
  width: 50px;
  height: 50px;
  line-height: 33px;
  cursor: pointer;
  -webkit-transition: all cubic-bezier(0.18, 0.89, 0.4, 1.25) 0.35s;
  transition: all cubic-bezier(0.18, 0.89, 0.4, 1.25) 0.35s;
  display: inline-block;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  background: #3ac8c4;
  margin-right: 24px;
  margin-bottom: 0;
  color: #ffffff;
}
.currency {
  background: #3ac8c4;
  border: 1px solid #3ac8c4;
}
.lang {
  background: #fff;
  border: 1px solid #fff;
}
@media (max-width: 88em) {
  .navbar {
    padding: 0 5%;
    margin: 0;
    max-height: 100px;
  }
}
@media (max-width: 36em) {
  .navbar {
    max-height: 80px;
  }
  .navbar-brand img {
    width: 160px;
    height: 22px;
  }
  .circle {
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin-right: 5px;
  }
  .navbar-nav .nav-item {
    line-height: 40px;
  }
  .navbar-nav {
    margin-bottom: 5px;
  }
  .login {
    margin-right: 0;
  }
}
@media (max-width: 30em) {
  .nav-section {
    margin-left: 0;
  }
}

/** Footer **/
footer {
  padding: 0 5%;
}
.footer-container {
  max-width: 1536px;
  margin-top: 80px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  display: flex;
  width: 100%;
  padding: 0 5%;
  border-width: 0;
  overflow: hidden;
}
.footer-content {
  width: 22%;
  padding: 0;
  vertical-align: top;
  text-align: left;
  border-bottom-width: 0;
  margin-bottom: 48px;
}
@media (max-width: 48em) {
  .footer-container {
    -webkit-box-pack: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: space-around;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 100px 0 50px;
  }
  .footer-content {
    width: 40%;
  }
}
.footer-content h4 {
  font-size: 2rem;
  padding-bottom: 18px;
  color: #303030;
  text-transform: uppercase;
  border-bottom: 1px solid #8fe4e2;
}
/* .footer-content:last-child h4 {
  border-bottom-width: 0;
  margin-bottom: 0;
} */
.footer-content ul li {
  text-align: left;
  margin: 8px 0;
  padding: 0;
}
.footer-btn {
  font-size: 1.4rem;
  padding: 15px;
  margin: 0 0 8px 0;
  box-sizing: border-box;
  border: 1px solid #8fe4e2;
  border-radius: 4px;
  cursor: pointer;
  background: #fff;
  display: block;
  width: 100%;
}
@media (max-width: 48em) {
  .footer-container {
    grid-template-columns: 1fr 1fr;
  }
  .footer-content h4 {
    width: 90%;
  }
  .footer-content {
    margin-bottom: 25px;
  }
}

.checkout-footer {
  padding: 0 36px;
  display: flex;
  white-space: nowrap;
}

.checkout-footer li {
  margin: 1rem;
  text-decoration: underline;
}

@media (max-width: 36em) {
  .checkout-footer {
    display: grid;
    grid-template-columns: 1fr;
  }
}
