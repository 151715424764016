.react-tabs__tab-list {
  margin: 0 0 10px -12px;
  padding: 0;
}

@media only screen and (max-width: 1024px) {
  .react-tabs__tab-list {
    margin: 0 0 10px 16px;
    padding: 0;
  }
}

.react-tabs__tab {
  display: inline-block;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid #fd5f00;
  border-radius: 16px;
  margin: 0 8px 8px 0;
}
.react-tabs__tab:hover {
  box-shadow: 1px 1px 10px #999;
}
.react-tabs__tab--selected {
  background: #fd5f00;
  color: #fff;
  border-radius: 16px;
  box-shadow: 1px 1px 10px #999;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

@media only screen and (max-width: 1024px) {
  .react-tabs__tab-panel--selected {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.6rem;
  }
}
