/* ------ **********Table of Contents 
* 1. Basic styling
** 2. Colors
** 3. Secondary & Reusable styling
** 4. Grids
** 5. Banner
** 6. Main Block
** 7. Newsletter - footer
***** 8. About page
***** 9. Magazine page
***** 10. Article pages
***** 11. Destination pages - styles for Destination pages (Top and Cruise pages) also Magazine Destination section
***** 12. Tour & Art Destination page
***** 14. Faqs, General & Term pages
***** 15. Faq page
***** 16. General Term page
***** 17. Terms of Use page
***** 18. Legal page
***** 19. Newsletter page
***** 20. Contact page
********** -------- */

/* 1. Basic Styling */
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: 'Karla', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 1.42857143;
  color: #303030;
  background-color: #fff;
}
/* TODO: replace all raw h1..h6 w/ Heading component, delete this stuff */
h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 2.25em;
}
h3 {
  font-size: 2.15em;
}
h4 {
  font-size: 1.75em;
}
@media (max-width: 64em) {
  h4 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 2em;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0 0 16px;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1 sup {
  top: -0.75em;
  font-size: 1.4rem;
  text-transform: uppercase;
}
a {
  color: #303030;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
ul,
li {
  list-style-type: none;
  margin-top: 0;
  padding: 0;
}
img {
  /* max-width: 100%; */
  width: 100%;
}

/** 2. Colors **/
.upper {
  text-transform: uppercase;
}
.gray {
  color: #939393;
}
.white {
  color: #ffffff;
}
.blue-bg {
  background: #3ac8c4;
}
.blue {
  color: #3ac8c4;
}
.red-bg {
  background: #fd5f00;
}
.red {
  color: #fd5f00;
}
.dark-bg {
  background-color: #303030;
}
.dark {
  color: #303030;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.bg-light {
  background-color: #f0f0f0;
}
.overlay-red {
  background: rgba(254, 71, 0, 0.9);
}
.text-center {
  text-align: center !important;
}
/** 3. Secondary & Reusable classes **/
.bg-plane {
  background-image: url(/img/plane-bg.png);
  background-repeat: repeat;
}

.bg-travel {
  background-image: url(/img/travel-bg.jpg);
  background-repeat: repeat;
}
.fw-6 {
  font-weight: 600;
}
/** Secondary p - used in destination and magazine sections **/
.secondary p {
  font-weight: 600;
  color: #fd5f00;
}
/** Transition **/
.transition {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
/** Box Shadow for destination and article sections **/
.box-shadow {
  height: 100%;
  box-shadow: #faf9f9 15px 15px;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
a:hover .box-shadow,
.box-shadow:hover {
  box-shadow: #8fe4e2 15px 15px;
}
/** Back Btn **/
.back-button {
  margin: 0 auto;
  max-width: 1536px;
  padding: 75px 0px 65px;
}
@media (max-width: 36em) {
  .back-button {
    padding: 25px 0px 25px;
  }
}

/** 4. Grids - reusable grid sections**/
.grid-2-1 {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2em;
}
.grid-2 {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.grid-3 {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.grid-4 {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 88em) {
  .grid-3 {
    grid-template-columns: 1fr 1fr;
  }
  .grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 64em) {
  .grid-2-1,
  .grid-2 {
    grid-template-columns: 1fr;
  }
  .grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 48em) {
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }
}

/** 5. Banner **/
.banner {
  width: 100%;
  height: 450px;
}

.banner-bg {
  position: relative;
}

.banner-title {
  position: absolute;
  padding: 1rem 2rem 0.5rem 6.5rem;
  left: 0;
  bottom: 0;
  margin-bottom: 35px;
  border-radius: 0 8px 8px 0;
}

.banner-title h1 {
  font-family: 'Open Sans Condensed', sans-serif;
}

.banner.cruises-img {
  width: 100%;
  height: 685px;
  background-size: cover;
}

.banner.magazine-bg-img,
.banner.tour-img,
.banner.destination-img,
.banner.contact-us-img,
.banner.contact-us-img,
.banner.legal-img,
.banner.newsletter-img {
  width: 100%;
  height: 250px;
  background-size: cover;
}

/** About US banner **/
.about-us-img {
  background: url('/img/aboutus.jpg') no-repeat center;
  background-size: cover;
}
/** Magazine banner **/
.magazine-bg-img {
  background: url(/img/magazine.jpg) no-repeat center;
} /** Destination banner **/
.destination-img {
  background: url(/img/destination.jpg) no-repeat;
}
/** Tour banner **/
.tour-img {
  background: url(/img/toursanda.jpg) no-repeat center;
}
/** Cruises banner **/
.cruises-img {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url(/img/cruise-bg.jpg) no-repeat center bottom;
}
/** Contact banner **/
.contact-us-img {
  background: url(/img/map_pattern_1.png) no-repeat center;
}
/** Legal banner **/
.legal-img {
  background: url(/img/aboutus.jpg) no-repeat center;
}
/** Newsletter banner **/
.newsletter-img {
  background: url(/img/news-bg.png) no-repeat center;
}
/** Article Armenia banner **/
.arm-img {
  background: url(/img/mag-yerevan-large.jpg) no-repeat center bottom;
  background-size: cover;
}
/** Article Estonia banner **/
.est-img {
  background: url(/img/mag-tallinn.jpg) no-repeat center;
  background-size: cover;
}
/** Article Czech banner **/
.czech-img {
  background: url(/img/mag-prague.jpg) no-repeat center;
  background-size: cover;
}

/** 6. Main Block - 
    Main resuable text section throughout all pages **/
.main-wrapper {
  margin: 0 auto;
  max-width: 1536px;
  padding: 75px 25px;
}
.main-wrapper.pb-5 {
  padding-bottom: 5px;
}
.main-title {
  line-height: 1;
}
.main-title:after {
  display: block;
  content: '';
  clear: both;
  position: relative;
  left: 0;
  bottom: 0;
  max-width: 400px;
  height: 1px;
  width: 50%;
  border-bottom: 2px solid #fd5f00;
  margin: 8px auto 20px;
  padding-top: 1px;
}
.main-title.blue-title:after {
  border-bottom: 2px solid #3ac8c4;
}
.main-title.blue-title-sm:after {
  max-width: 150px;
  width: 50%;
  border-bottom: 2px solid #3ac8c4;
}
.main-wrapper p {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0 350px;
  text-align: center;
}
@media (max-width: 88em) {
  .main-wrapper p {
    padding: 0 75px;
  }
}
@media (max-width: 36em) {
  .main-wrapper p {
    padding: 0 15px;
  }
  .main-wrapper {
    padding: 55px 25px;
  }
}

/** 7. Newsletter -
    footer Newsletter and Social Contact section **/
.newsletter {
  height: auto;
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  margin-top: 2rem;
}
.newsletter-signup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 75px 10px;
  background: #f0f0f0;
}

.newsletter-circle {
  width: 500px;
  height: 500px;
  border-radius: 50%;
  box-sizing: content-box;
  z-index: 1;
  background: #30c8c4;
}
.newsletter-circle h1 {
  padding-top: 160px;
  text-align: center;
  color: #fff;
}
.newsletter-circle h4 {
  text-align: center;
  font-weight: 600;
}
.news-form-container {
  margin: 30px auto;
  position: relative;
  padding: 0;
  width: 80%;
}
.news-form-container input {
  height: 50px;
  font-size: 14px;
  padding: 0 5px 0 25px;
  width: 100%;
  border-width: 0;
  box-sizing: border-box;
  font-family: Karla, sans-serif;
  color: #999;
  border-radius: 4px;
}
.newsletter-button input {
  width: 100%;
  border-width: 0;
  box-sizing: border-box;
  font-family: Karla, sans-serif;
  color: #999;
  border-radius: 4px;
}
.newsletter-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background: #fd5f00;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.newsletter-button label {
  padding: 10px;
  width: 100%;
  height: 100%;
}
.newsletter-button label svg {
  width: 100%;
  height: 100%;
}
.newsletter-submit {
  display: none;
}
label {
  display: inline-block;
  max-width: 100%;
}
@media (max-width: 70em) {
  .newsletter-circle {
    width: 350px;
    height: 350px;
  }
  .newsletter-circle h1 {
    padding-top: 100px;
    font-size: 2em;
  }
  .news-form-container {
    margin: 20px auto;
  }
  .newsletter {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}

@media (max-width: 36em) {
  .newsletter {
    padding: 1rem;
  }
  .newsletter-circle {
    width: 290px;
    height: 290px;
  }
  .newsletter-circle h1 {
    padding-top: 100px;
    font-size: 1.75em;
  }
  .newsletter-circle h4 {
    font-size: 1.25em;
  }
  .news-form-container {
    margin: 20px auto;
  }
  .news-form-container {
    width: 75%;
  }
  .news-form-container input {
    height: 40px;
  }
}

/** 7.a Newsletter Contact Section **/
.newsletter-contact-container {
  text-align: center;
  width: 100%;
  margin: 2rem auto;
}
.newsletter-contact-block {
  width: 80%;
  vertical-align: middle;
  color: #303030;
  background: #8fe4e2;
  margin: 0 auto;
  font-size: 1.75rem;
  letter-spacing: 1px;
  text-align: center;
  padding: 25px 5px;
  border-radius: 4px;
}

.newsletter-contact-img {
  width: 50%;
  max-width: 400px;
  margin-bottom: 2rem;
}
.newsletter-contact-social {
  margin: 3rem;
  display: flex;
  justify-content: center;
}
.newsletter-contact-social a {
  padding: 0 15px;
  color: #646464;
  font-size: 3.6rem;
  -webkit-transition: color 0.35s;
  -moz-transition: color 0.35s;
  -ms-transition: color 0.35s;
  -o-transition: color 0.35s;
  -webkit-transition: color 0.35s;
  transition: color 0.35s;
  display: flex;
  align-items: center;
}

.newsletter-contact-social a:hover {
  color: #fd5f00;
}
@media (max-width: 64em) {
  .newsletter-contact {
    border: none;
  }
}
@media (max-width: 36em) {
  .newsletter-contact-block {
    width: 100%;
    font-size: 1.5rem;
    letter-spacing: 0px;
    padding: 15px 5px;
  }
}

/***** 8. About page *****/
/*** 8a. About Block Content ***/
.about-wrapper {
  max-width: 1536px;
  margin: 0 auto;
  padding: 4rem 8rem;
}
@media (max-width: 64em) {
  .about-wrapper {
    padding: 3em 5%;
  }
}
.about-text p {
  margin-left: 94px;
}
.about-wrapper .circle {
  float: left;
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-wrapper .circle:hover {
  background: #fd5f00;
}
.about-mission {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mission-text {
  position: relative;
  padding-left: 5%;
}
.mission-text p:after {
  background: url(/img/quote-icon.png) no-repeat top right;
  background-size: contain;
  margin-top: 30px;
  margin-right: 25px;
  content: '';
  top: 0;
  right: 0;
  position: absolute;
  width: 150px;
  height: 150px;
}
.about-secondary {
  padding-top: 25px;
}
@media (max-width: 64em) {
  .about-text {
    padding-bottom: 15px;
  }
  .mission-text h3 {
    border-top: 1px solid #ccc;
    padding-top: 15px;
  }
  .about-secondary {
    padding-top: 0;
  }
  .mission-text {
    padding-left: 0;
  }
}
@media (max-width: 36em) {
  .about-text p {
    margin-left: 0;
    padding-bottom: 0px;
    text-align: center;
  }
  .about-wrapper .circle {
    float: none;
    display: block;
    margin: auto;
  }
}

/*** 8b. About Team Group Section ***/
.team-container {
  min-height: 450px;
}
@media (max-width: 64em) {
  .team-content {
    min-height: 300px;
    padding: 10% 0;
  }
  .team-group-bg {
    min-height: 400px;
  }
}
@media (max-width: 48em) {
  .team-content {
    min-height: 300px;
    padding: 15% 0;
  }
}
.team-map-bg {
  background: url(/img/team-map.jpg) center;
  background-size: cover;
  padding: 0 10%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.team-group-bg {
  background: url('/img/team-group.jpg') center;
  background-size: cover;
}
.team-content p {
  line-height: 1.7;
  margin-bottom: 0;
}

/*** 8c. About Team Member Section ***/
.member-wrapper {
  margin: 0 auto;
  max-width: 1536px;
  padding: 4rem;
  grid-row-gap: 2em;
}
.member-wrapper .ad {
  grid-column: span 4;
}
@media (max-width: 98em) {
  .member-wrapper.grid-4 {
    grid-template-columns: repeat(3, 1fr);
  }
  .member-wrapper .ad {
    grid-column: span 3;
  }
}
@media (max-width: 64em) {
  .member-wrapper.grid-4 {
    grid-template-columns: repeat(2, 1fr);
  }
  .member-wrapper .ad {
    grid-column: span 2;
  }
}
@media (max-width: 48em) {
  .member-wrapper.grid-4 {
    grid-template-columns: 1fr;
  }
  .member-wrapper .ad {
    grid-column: span 1;
  }
}
.member-content {
  position: relative;
  max-width: 300px;
  max-height: 300px;
  margin: auto;
  pointer-events: none !important;
}
.member-info {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  margin-top: 5%;
  margin-left: 5%;
  opacity: 0;
  pointer-events: all !important;
  cursor: default;
}
.member-content:hover .member-info {
  opacity: 1;
}
.member-wrapper .member-info p {
  color: #fff;
  padding: 25px 25px 35px;
  font-weight: 500;
}
.member-info.border-radius p {
  padding: 50px 30px 25px 50px;
  font-weight: 500;
}
.member-info .member-social a {
  color: #fff;
  padding: 15px 15px 0;
  font-size: 1.6rem;
  z-index: 1000;
}
.member-info.border-radius .member-social a:hover {
  color: #303030;
}
.member-wrapper h4 {
  margin-top: 15px;
  margin-bottom: 0;
  color: #303030;
}
.member-wrapper p {
  font-weight: 600;
  color: #fd5f00;
  margin: 0;
  padding: 0;
}
.member-img {
  max-width: 300px;
  height: 300px;
  margin: auto;
}
.member-img-1 {
  background: url('/img/nic.jpg') center;
  background-size: cover;
}
.member-img-2 {
  background: url('/img/sarah.jpg') center;
  background-size: cover;
}
.member-img-3 {
  background: url('/img/daniel.jpg');
  background-size: cover;
}
.member-img-4 {
  background: url('/img/katharina.jpg');
  background-size: cover;
}
.member-img-5 {
  background: url('/img/laura.jpg') center;
  background-size: cover;
}
.member-img-6 {
  background: url('/img/yasmin.jpg') center right;
  background-size: cover;
}
.member-img-7 {
  background: url('/img/irfan.jpg') center right;
  background-size: cover;
}
.member-img-8 {
  background: url('/img/ali.jpg') center right;
  background-size: cover;
}
.member-img-9 {
  background: url('/img/ray.jpg') center right;
  background-size: cover;
}
.member-img-10 {
  background: url('/img/adane.jpg');
  background-size: cover;
}
.member-img-11 {
  background: url('/img/srdjan.jpg') center right;
  background-size: cover;
}
.member-img-12 {
  background: url('/img/sandra.jpg');
  background-size: cover;
}
.member-img-13 {
  background: url('/img/tina.jpg') center;
  background-size: cover;
}
.member-img-14 {
  background: url('/img/christian.jpg') center;
  background-size: cover;
}
.member-img-15 {
  background: url('/img/lars.jpg') 0 -32px;
  background-size: cover;
}

.member-img-16 {
  background: url('/img/david.jpg');
  background-size: cover;
}
@media (max-width: 36em) {
  .member-content,
  .member-info,
  .member-img-1,
  .member-img-2,
  .member-img-3,
  .member-img-4,
  .member-img-5,
  .member-img-6,
  .member-img-7,
  .member-img-8,
  .member-img-9,
  .member-img-10,
  .member-img-11,
  .member-img-12,
  .member-img-13,
  .member-img-14,
  .member-img-15,
  .member-img-16 {
    max-width: 250px;
    max-height: 250px;
  }
  .member-info.border-radius p {
    padding: 40px 30px 15px 45px;
    font-size: 1.2rem;
  }
}
.border-radius {
  border-radius: 50%;
}

/***** 9. Magazine page *****/
/** 9a. Magazine logo title**/
.magazine-logo {
  padding: 5% 4% 0 4%;
}
.magazine-logo img {
  width: 40%;
}

.line-img-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.line-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
}
.line {
  width: 38%;
  height: 1px;
}
.magazine-sub-title {
  font-size: 2.75rem;
  padding-left: 5px;
  color: #fff;
}

/** 9b. Top Magazine section **/
.magazine-wrapper {
  margin: 0 auto;
  max-width: 1536px;
  padding: 75px 0px 0px;
  grid-column-gap: 4em;
  grid-row-gap: 4em;
}
.magazine-content {
  background: #fff;
  position: relative;
  pointer-events: none !important;
}
.magazine-content img {
  height: 260px;
  object-fit: cover;
}
.magazine-info {
  padding: 5px 15px 10px;
}
.magazine-info h4 {
  margin-bottom: 0;
}
.magazine-info p {
  margin: 0;
  font-size: 1.4rem;
}
.magazine-arrow {
  position: absolute;
  bottom: 0;
  right: 0;
}
.arrow-2 {
  width: 40px;
  margin-right: 15px;
  background: url(/img/arrow-black.png) no-repeat;
  height: 25px;
  background-size: contain;
}
a:hover .arrow-2 {
  background: url(/img/arrow-red.png) no-repeat;
  background-size: contain;
  margin-right: 10px;
}
.magazine-wrapper .ad {
  grid-column: span 3;
  margin-top: 10px;
  margin-bottom: 25px;
}
@media (max-width: 88em) {
  .magazine-wrapper {
    grid-column-gap: 3em;
    grid-row-gap: 3em;
  }
  .magazine-wrapper .ad {
    grid-column: span 2;
  }
  .magazine-sub-title {
    font-size: 2.5rem;
  }
}
@media (max-width: 64em) {
  .magazine-sub-title {
    font-size: 2rem;
  }
  .magazine-info h4 {
    font-size: 1.5em;
  }
}
@media (max-width: 48em) {
  .magazine-wrapper {
    padding: 50px 10% 0px;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
  }
  .magazine-wrapper .ad {
    grid-column: span 1;
  }
  /* .line-img {
    padding-left: 22%;
    margin-top: -5px;
  } */
  .magazine-sub-title {
    font-size: 1.5rem;
  }
}

/***** 10. Article pages *****/
.article-wrapper {
  min-height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  padding: 65px 10% 35px;
}
.article-wrapper .ad {
  margin-top: 0;
  grid-column: span 2;
}
@media (max-width: 120em) {
  .article-wrapper {
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 3em;
    grid-row-gap: 3em;
  }
}
@media (max-width: 88em) {
  .article-wrapper {
    grid-template-columns: 3fr 2fr;
    grid-column-gap: 3em;
    grid-row-gap: 3em;
  }
}
@media (max-width: 64em) {
  .article-wrapper {
    grid-template-columns: 1fr;
    grid-column-gap: 2em;
    grid-row-gap: 2em;
    padding: 35px 5%;
  }
  .article-wrapper .ad {
    grid-column: span 1;
  }
  .main h3 {
    font-size: 1.5em;
  }
}
.article-container {
  width: 100%;
}
.article-content.main {
  background: #fff;
  padding: 30px 60px;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
}
@media (max-width: 64em) {
  .article-content.main {
    padding: 30px;
  }
}
.main h1 {
  margin: 0;
}
.main h3:after,
.feature:after {
  display: block;
  content: '';
  clear: both;
  position: relative;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #3ac8c4;
  margin: 10px 0 5px;
  padding-top: 1px;
}
.feature {
  text-transform: uppercase;
  font-size: 1.6rem;
  color: rgb(111, 111, 111);
  letter-spacing: 1px;
}
span.article-author {
  display: inline-block;
  margin: 2rem 0;
  padding: 1rem 3rem;
  border: 1px solid #3ac8c4;
  border-radius: 30px;
}
span.article-author:hover {
  background: #3ac8c4;
  color: #fff;
}
.article-next {
  position: absolute;
  bottom: 0;
  padding: 30px 0 25px;
}
@media (max-width: 88em) {
  .article-next {
    position: relative;
    bottom: auto;
    padding: 0;
  }
}
a.article-more {
  padding: 0.5rem 1.5rem;
  border: 1px solid #fd5f00;
  border-radius: 30px;
  margin-right: auto;
  float: right;
}
a.article-more:hover {
  background: #fd5f00;
  color: #fff;
}
.article-author small,
.article-next a small,
.article-more a small {
  font-size: smaller;
}
.article-next a {
  margin-bottom: 5px;
  padding: 1rem;
  border: 1px solid #fd5f00;
  border-radius: 40px;
  color: #fd5f00;
  background: #fff;
}
.article-next a:hover {
  color: #fff;
  background: #fd5f00;
}
.article-attraction {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 2em;
}
.side-content {
  padding: 5px 15px 20px;
}
.side-content h4 {
  font-size: 1.6rem;
}
.side-content h5 {
  font-size: 1.2rem;
  color: #fe4802;
  font-weight: 600;
  margin: 10px 0 0;
  display: inline-block;
}
.article-btn a {
  color: #fff;
  background: #fd5f00;
  border-radius: 50px;
  padding: 0.75em 2.75em;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.article-btn a:hover {
  background: #303030;
}

/***** 11. Destination pages
    Section styles for Destination pages (Top and Cruise pages) also Magazine Destination section *****/
.dest-wrapper {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  padding: 2rem 5%;
}
.dest-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #fff;
  margin: 0;
  padding: 0;
  line-height: 0;
  border-radius: 4px;
}
.dest-info {
  padding: 20px 15px 10px;
  line-height: 1.4;
}
.dest-info h4 {
  margin-top: 15px;
  margin-bottom: 5px;
}
.dest-info p {
  margin: 0 0 5px;
  padding: 0;
  text-align: center;
}
.dest-btn {
  color: #fff;
  background-color: #fd5f00;
  border-width: 0;
  border-radius: 30px;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: inline-block;
  height: 45px;
  width: 120px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
}
.dest-content img {
  border-radius: 4px 0 0 4px;
}

@media (max-width: 110em) {
  .dest-info {
    padding: 5px 15px;
  }
  .dest-info p {
    margin: 0;
    font-size: 1.4rem;
  }
  .dest-info h4 {
    margin-bottom: 0;
  }
  .dest-btn {
    height: 40px;
  }
}
@media (max-width: 88em) {
  .dest-info {
    padding: 0px 15px;
  }
}
.dest-btn:hover {
  background-color: #303030;
}
.dest-wrapper .ad {
  grid-column: span 2;
  margin-bottom: 35px;
}
.magazine.back-button {
  padding: 0px 10% 65px;
}
@media (max-width: 74em) {
  .dest-container {
    grid-template-columns: 1fr;
    text-align: left;
  }
  .dest-info p {
    margin: 0;
    display: block;
    font-size: 1.4rem;
  }
  a.dest-btn-link {
    float: right;
    margin-top: -50px;
  }
}
@media (max-width: 64em) {
  .dest-container {
    text-align: center;
  }
  a.dest-btn-link {
    float: none;
    margin-top: 0;
  }
}
@media (max-width: 48em) {
  .dest-wrapper {
    grid-row-gap: 2em;
    grid-template-columns: 1fr;
    padding: 0 3rem;
  }
  .dest-wrapper .ad {
    grid-column: span 1;
  }
}
@media (max-width: 36em) {
  .dest-wrapper {
    padding: 0 3rem;
  }
}
.destination.back-button {
  padding: 75px 10% 65px;
}

/***** 12. Tour & Art Destination page *****/
.tour-wrapper {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 4em;
  grid-row-gap: 4em;
  padding: 65px;
  margin: 0 auto;
  max-width: 1536px;
}
.tour-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  margin: 0;
  padding: 0;
  justify-items: center;
  line-height: 0;
  background: #fff;
}
.tour-container h4 {
  margin-top: 5px;
}

.tour-container p {
  text-align: center;
}
.tour-wrapper .ad {
  grid-column: span 3;
  margin-bottom: 35px;
  margin-top: 0;
}
@media (max-width: 68em) {
  .tour-wrapper {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 3em;
    grid-row-gap: 3em;
  }
  .tour-wrapper .ad {
    grid-column: span 2;
  }
}
@media (max-width: 44em) {
  .tour-wrapper {
    grid-template-columns: 1fr;
    padding: 25px 10% 0px;
  }
  .tour-wrapper .ad {
    grid-column: span 1;
  }
}
.tour-container .dest-info {
  padding: 20px 15px 25px;
}
.tour-container .dest-info p {
  margin: 0 0 5px;
}
.tour-container h5 {
  font-size: 1.2em;
  font-weight: 600;
  margin-top: 10px;
}
.tour-container a.dest-btn-link {
  float: none;
}

/*** 13. Accordian styles ***/
:focus {
  outline: none;
}
.ac {
  margin-top: 1.5em;
  background-color: #fbfbfb;
  border: 1px solid transparent;
}
.ac.active {
  border: 1px solid #fd5f00;
  background-color: #ffffff;
}
.ac-a ul {
  list-style-type: disc;
  margin-top: 0;
  padding: 0 2.5em;
}
.ac-a li {
  list-style-type: disc;
  margin: 0 0.5em;
}
.ac > .ac-q {
  font-size: 1.2em;
  color: #303030;
  padding: 1em 3.5em 0.75em 1.5em;
  margin: 0;
  text-decoration: none;
  display: block;
  cursor: pointer;
  position: relative;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.ac > .ac-q::after {
  content: '';
  border: solid #fd5f00;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  transition: transform 0.35s ease-out;
  text-align: center;
  width: 10px;
  right: 1.5em;
  top: 40%;
  position: absolute;
}
.ac > .ac-a {
  overflow: hidden;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}
.ac > .ac-a p {
  font-size: 1em;
  line-height: 1.5;
  color: #646464;
  margin: 0;
  padding: 0 1.75em 1.25em;
}
.ac.active > .ac-q::after {
  transform: rotate(-135deg);
}
a.qa-btn {
  cursor: pointer;
}

/***** 14. Faqs, General & Term pages *****/
ol li {
  list-style-type: decimal;
}
.faq-toc a li,
.gen-toc a li,
.gen-toc span li,
.terms-toc a li,
.terms-toc2 a li {
  font-size: 1.2em;
  margin: 10px 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -ms-transition: all 0.35s;
  -o-transition: all 0.35s;
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
}
.faq-toc a:hover li,
.gen-toc a:hover li,
.terms-toc a:hover li,
.terms-toc2 a:hover li {
  color: #fd5f00;
}
.faq-title,
.gen-title {
  background-color: #f9f9f9;
}
.faq-container,
.gen-container,
.terms-container {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.faq-title-container,
.faq-toc-container,
.gen-title-container,
.gen-toc-container,
.terms-toc-container,
.terms-toc2-container {
  height: auto;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr;
}
.faq-title-content h3,
.gen-title-content h3 {
  text-transform: uppercase;
}
.faq-title-content h4,
.gen-title-content h4 {
  color: rgb(147, 147, 147);
}
.faq-title-content h4:after,
.gen-title-content h4:after {
  display: block;
  content: '';
  clear: both;
  position: relative;
  left: 0;
  bottom: 0;
  max-width: 400px;
  height: 1px;
  border-bottom: 2px solid #3ac8c4;
  margin: 5px 0 15px;
  padding-top: 1px;
}
.faq-toc-content h4,
.gen-toc-content h4 {
  color: #fd5f00;
}

.terms-toc-content ol li {
  color: #8fe4e2;
}
@media (max-width: 64em) {
  .faq-container,
  .gen-container,
  .terms-container {
    height: auto;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
  }
  .faq-title-content,
  .faq-toc-content,
  .gen-title-content,
  .gen-toc-content,
  .terms-toc-content,
  .terms-toc2-content {
    padding: 2.5em;
  }
}
@media (max-width: 36em) {
  .faq-title-content h4:after,
  .gen-title-content h4:after {
    max-width: 100px;
  }
  .faq-title-content,
  .gen-title-content {
    padding: 2.5em 5em;
  }
}
.qa-container,
.gen-qa-container {
  padding: 50px 0px;
}
.qa-section {
  background: #fff;
  margin: 0 auto;
  max-width: 1536px;
}
.qa-section h4 {
  color: #30c8c4;
  font-size: 1.5em;
  margin-top: 25px;
}
.qa-section-container {
  padding: 0.75em 0;
}

/***** 15. Faq page *****/
.faq-toc {
  background-color: #8fe4e2;
}
.faq-title-container,
.faq-toc-container {
  min-height: 450px;
}
.faq-title-content h4:after {
  width: 50%;
}

/***** 16. General Term page *****/
.gen-toc a li {
  color: #3ac8c4;
}
.gen-toc span li {
  color: #3ac8c4;
}
.gen-toc span li:hover {
  color: #fd5f00;
}
.gen-toc {
  background-color: #303030;
}
.gen-title-container,
.gen-toc-container {
  min-height: 350px;
}
.gen-title-content h4:after {
  width: 100%;
}

@media (max-width: 64em) {
  .faq-title-container,
  .gen-title-container {
    min-height: 300px;
  }
}
/** 16.a General Accordian **/
.gen-qa-container {
  padding: 50px 5%;
}
.gen-qa-container .ac-q {
  font-size: 1.5em;
}
.gen-qa-container .ac.active {
  border: 1px solid transparent;
}
.gen-qa-container .ac.active > .ac-q {
  padding: 1em 3.5em 1em 1.5em;
  background-color: #3ac8c4;
}
.gen-qa-container .ac > .ac-a p {
  padding: 1.25em 1.75em;
}
.gen-qa-container p.gen-tldr {
  border-bottom: 1px solid #eee;
}
.gen-qa-container .ac > .ac-q::after {
  top: 45%;
}

/***** 17. Terms of Use page *****/
.terms-tob-title {
  padding: 3em 1.75em;
  text-align: center;
  border-top: 1px solid rgba(0, 0, 0, 0.03);
}
.terms-toc a li {
  color: #3ac8c4;
}
.terms-toc2 a li {
  color: #303030;
}
.terms-toc a:hover li,
.terms-toc2 a:hover li {
  color: #fd5f00;
}
a.qa-btn {
  cursor: pointer;
}
.terms-toc {
  background-color: #303030;
}
.terms-toc2 {
  background-color: #8fe4e2;
}
.terms-toc-container,
.terms-toc2-container {
  min-height: 350px;
}
@media (max-width: 64em) {
  .terms-container {
    background: #303030;
  }
  .terms-toc,
  .terms-toc2 {
    background-color: transparent;
  }
  .terms-toc2 a li {
    color: #3ac8c4;
  }
  .terms-toc-container,
  .terms-toc2-container {
    min-height: 100%;
    height: auto;
    width: 100%;
    display: grid;
    align-items: normal;
    justify-items: left;
    grid-template-columns: 1fr;
  }
  .terms-toc-content {
    padding: 2.5em 2.5em 0;
  }
  .terms-toc2-content {
    padding: 0 2.5em 2.5em;
    margin-top: -15px;
  }
  .terms-tob-title {
    padding: 2.5em 1.75em;
  }
}

/***** 18. Legal page *****/
.legal-container {
  padding: 5em 10%;
  height: auto;
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: #8fe4e2;
}
@media (max-width: 64em) {
  .legal-container {
    grid-template-columns: 1fr 1fr;
    justify-items: left;
  }
  .legal-content {
    padding: 1em 0;
  }
}
@media (max-width: 34em) {
  .legal-container {
    grid-template-columns: 1fr;
  }
}

/***** 19. Newsletter page *****/
.news-text-wrapper {
  max-width: 1536px;
  margin: 0 auto;
  padding: 4em;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 2em;
  align-items: center;
}
.news-signup-wrapper {
  height: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
}
.news-text p {
  line-height: 1.7;
  margin-right: 15px;
}
@media (max-width: 64em) {
  .news-text-wrapper {
    padding: 3em 5%;
    grid-template-columns: 1fr;
  }
  .news-signup-wrapper {
    border-top: 1px inset rgba(48, 48, 48, 0.05);
  }
  .news-text {
    padding: 15px;
  }
}
@media (max-width: 36em) {
  .news-text p {
    margin-right: 0;
    padding-bottom: 15px;
    font-size: 1.5rem;
  }
}
.news-signup-wrapper .newsletter-signup {
  padding: 0;
  background: transparent;
}
.news-signup-wrapper .newsletter-circle {
  width: 400px;
  height: 400px;
  margin: 0 auto;
}
.news-signup-wrapper .newsletter-circle h1 {
  padding-top: 115px;
  font-size: 2em;
}
.news-signup-wrapper .newsletter-circle h4 {
  padding-top: 10px;
  font-size: 1.25em;
}
.news-signup-wrapper .news-form-container {
  margin: 20px auto;
}
.news-signup-wrapper .news-form-container input {
  height: 45px;
}
#newsletter-full .newsletter {
  padding: 0 10%;
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 2%;
}
#newsletter-full .newsletter-contact {
  border: none;
}
#newsletter-full .newsletter-contact-block {
  width: 60%;
}
@media (max-width: 64em) {
  #newsletter-full .newsletter-contact-block {
    width: 100%;
  }
  .news-signup-wrapper .newsletter-circle {
    width: 350px;
    height: 350px;
    margin: 70px auto 30px;
  }
  .news-signup-wrapper .newsletter-circle h1 {
    padding-top: 100px;
  }
}
@media (max-width: 36em) {
  .news-signup-wrapper .newsletter-circle {
    width: 300px;
    height: 300px;
  }
  .news-signup-wrapper .newsletter-circle h1 {
    padding-top: 100px;
    font-size: 1.75em;
  }
  .news-signup-wrapper .newsletter-circle h4 {
    padding-top: 0;
  }
  .news-signup-wrapper .news-form-container {
    margin: 10px auto;
  }
  .news-signup-wrapper .news-form-container input {
    height: 40px;
  }
}

/***** 20. Contact page *****/
.contact-form-wrapper {
  height: auto;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;
}
.contact-map-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 64em) {
  .contact-form-wrapper {
    grid-template-columns: 1fr;
  }
  .contact-map-content {
    min-height: 250px;
  }
}
@media (max-width: 36em) {
  .contact-map-container {
    grid-template-columns: 1fr;
  }
}
.contact-form-main {
  padding: 7em 10%;
  background: #8fe4e2;
}
.contact-form-main h1 {
  font-size: 2em;
  font-weight: 600;
  text-transform: uppercase;
}
.contact-form-main h4 {
  color: #fd5f00;
  font-size: 1.5em;
  margin-bottom: 20px;
}
.contact-form-text p {
  margin-left: 94px;
}
input.contact-name,
input.contact-email,
.contact-textarea textarea {
  width: 100%;
}
input.contact-name,
input.contact-email {
  font-size: 16px;
  border-radius: 40px;
  border: 1px solid transparent;
  padding: 10px 20px;
  margin-bottom: 20px;
  height: 45px;
}
button.contact-btn {
  background: #303030;
  border: transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 600;
  height: 55px;
  font-size: 1.2em;
}
button.contact-btn:hover {
  background: #fd5f00;
}
.contact-textarea textarea {
  border-radius: 5px 5px 0 5px;
  border: 1px solid transparent;
  padding: 10px 20px;
  height: 200px;
  margin-bottom: 25px;
  font-size: 16px;
}
.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  color: #fd5f00;
}
.contact-map-title {
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
}
.contact-overlay-red {
  width: 90%;
  height: 90%;
  background: rgba(254, 71, 0, 0.95);
  opacity: 0;
}
.contact-map-content:hover .contact-overlay-red {
  opacity: 1;
}
.contact-map-title h4 {
  position: absolute;
}
.map-marker {
  position: absolute;
  opacity: 0;
  color: #fff;
  margin-top: 50px;
}
.contact-map-content:hover .contact-map-title h4 {
  color: #fff;
  margin-top: -30px;
}
.contact-map-content:hover .map-marker {
  opacity: 1;
  margin-top: 100px;
}
.contact-map {
  height: 100%;
  width: 100%;
}
.map-1 {
  background: url('/img/aschaffenburg.png') no-repeat center;
  background-size: cover;
}
.map-2 {
  background: url('/img/konstanz.png') no-repeat center;
  background-size: cover;
}
.map-3 {
  background: url('/img/zurich.png') no-repeat center;
  background-size: cover;
}
.map-4 {
  background: url('/img/toronto.png');
  background-size: cover;
}
