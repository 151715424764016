html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Karla, sans-serif;
  background: #f4f4f4;
}

p,
label,
button,
div,
span,
input,
li,
h3 {
  font-family: Karla, sans-serif;
}

p {
  color: rgb(48, 48, 48);
  font-size: 1.6rem;
  text-align: left;
}

ul {
  padding: 0;
}

input[type='date']:focus::before,
input[type='date']:valid::before {
  display: none;
}

button {
  border-width: 0;
  background-color: transparent;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

a,
a:hover,
button,
button:hover {
  text-decoration: none;
  cursor: pointer;
}

.ad {
  position: relative;
  font-size: 30px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  font-family: Karla, sans-serif;
}
.ad:before,
.ad:after {
  position: absolute;
  top: 51%;
  overflow: hidden;
  width: 50%;
  height: 1px;
  content: '\a0';
  background-color: rgb(204, 204, 204);
}
.ad:before {
  margin-left: -50%;
  text-align: right;
}

#tourForm label input[type='radio'],
#tourForm label input[type='radio']:checked {
  visibility: hidden;
  display: none;
}

#tourForm input[type='radio']:checked {
  padding: 4px;
  border: 1px solid orange;
  border-radius: 40px;
}
