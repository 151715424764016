.rocket-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 9rem;
}

.rocket-container > * {
  margin: 0;
  padding: 0;
}
.rocket-container > svg {
  transform: scale(0.9);
}
.rocket {
  position: absolute;
  width: 400px;
  height: 400px;
  animation-name: spin;
  animation: spin 5s linear infinite;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 700px) {
  .rocket {
    width: 250px;
    height: 250px;
  }
  #moon {
    transform: scale(0.5);
  }
}
